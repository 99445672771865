.logo{
    display: block;
    width: $logo-width;
    pointer-events: none;
    mix-blend-mode: difference;
}

.heading{
    max-width: 600px;
    display: flex;
    align-items: center;
    .btn-back{
        margin-right: 20px;
    }
}

.menu{
    position: fixed;
    z-index: $z-high;
}