// What We Do
.wwd{
    &-services{
        margin-bottom: 50px;
        @include font-set(body-xl);
        .emoji{
            cursor: default;
        }
    }
    &-partners{
        @include font-set(body-xl);
    }
}

// Featured Projects
.fpl{
    margin-top: 100px;
    &-header{
        margin-bottom: 30px;
    }
}

// Proje Item
.prj{
    &-body{
        margin: 0 $container-padding-x-desktop;
        display: flex;
        padding: 0 235px 0 35px;
        max-width: 1700px;
    }
    &-header{
        margin: 0 $container-padding-x-desktop;
        @include font-set(body-xxl);
    }
    &-logo{
        margin-right: 50px;
    }
    &-block1{
        flex: 1;
    }
    &-medias{
        margin-top: 50px;
        .swiper-slide{
            width: 60vw;
        }
    }
}

// 404
.pnf{
    height: calc(100vh - 528px);
    &-title{
        @include font-set(body-xxl);
    }
    &-desc{
        @include font-set(body-xl);
    }
}

// Lab
.lab{
    &-list{
        margin-inline: $container-padding-x-desktop;
    }
}

.lit{
    &-header{
        @include font-set(body-xxl);
    }
    &-body{
        display: flex;
        align-items: flex-start;
        margin-top: 50px;
    }
    &-logo{
        flex: 0 0 180px;
        margin-right: 50px;
    }
    &-desc, &-techs{
        margin-bottom: 20px;
    }
}