@keyframes show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes hide {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes toTop {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes toTopDelayed{
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    80% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes toBottom {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    100% {
        transform: translateY(100%);
        opacity: 1;
    }
}
@keyframes toRight {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes logo {
    0% {
        transform: rotateX(90deg)
    }

    100% {
        transform: rotateX(0)
    }
}

@keyframes rotating {
    to {
        transform: rotate(360deg)
    }
}

@keyframes blink-animation {
    to {
      visibility: hidden;
    }
}

@keyframes pulse{
    0%  { opacity: 0; transform: scale(.8); }
    0%  { opacity: 0; transform: scale(.9); }
    40% { opacity: 1; transform: scale(1); }
    80% { opacity: 0; transform: scale(1.1); }
    100% { opacity: 0; transform: scale(.9); }
}
@keyframes beat{
    0%  { transform: scale(1.2); }
    50% { transform: scale(1.5); }
    100%{ transform: scale(1.2); }
}

@keyframes rocket{
    10% {
        transform: rotate(-1deg);
    }
    20% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(1deg);
    }
    40% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(-1deg);
    }
    60% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(1deg);
    }
    80% {
        transform: rotate(0deg);
    }
    90% {
        transform: rotate(-1deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rocket2{
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes ufo{
    10% {
        transform: rotate(-2deg);
    }
    30% {
        transform: rotate(2deg);
    }
    50% {
        transform: rotate(-2deg);
    }
    70% {
        transform: rotate(2deg);
    }
    90% {
        transform: rotate(-2deg);
    }
}

@keyframes ufo2{
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes loaderShow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes loaderMove {
    0%{
      transform: translateZ(-500px) rotate(0deg);
    }
    100%{
      transform: translateZ(500px) rotate(0deg);
    }
}

@keyframes loaderFade {
    0%{
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}