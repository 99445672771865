@import '../../../node_modules/swiper/swiper';

// Pagination
.swiper-pagination, .swiper-pagination-inner {
  // position: absolute;
  text-align: center;
  transition: 300ms opacity;
  // transform: translate3d(0,0,0);
  // z-index: 10;
  margin: 12px auto 0;
  display: none;
  justify-content: center;
  align-items: center;
  // height: 40px;
  
  &.swiper-pagination-hidden {
    opacity: 0;
  }
}
/* Common Styles */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
  // bottom: 10px;
  // left: 0;
  width: 100%;
}
/* Bullets */
// .swiper-pagination-bullets-dynamic {
//   // overflow: hidden;
//   // width: 100% !important;
//   font-size: 0;
//   .swiper-pagination-bullet {
//     display: none;
//   }
//   .swiper-pagination-bullet-active, .swiper-pagination-bullet-active-main, .swiper-pagination-bullet-active-prev, .swiper-pagination-bullet-active-next {
//     display: inline-flex;
//   }
//   .swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullet-active-next-next {
//     display: inline-flex;
//     &::before{
//       transform: scale(0.66);
//     }
//   }
// }
.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: $c-black;
  opacity: 0.3;
  outline: none;

  @at-root button#{&} {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none;
  }
  .swiper-pagination-clickable & {
    cursor: pointer;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1;
  // &::before{
    width: 8px;
    height: 8px;
  // }
  // background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

// .swiper-container-vertical {
//   > .swiper-pagination-bullets {
//     right: 10px;
//     top: 50%;
//     transform:translate3d(0px,-50%,0);
//     .swiper-pagination-bullet {
//       margin: 6px 0;
//       display: block;
//     }
//     &.swiper-pagination-bullets-dynamic {
//       top: 50%;
//       transform: translateY(-50%);
//       width: 8px;
//       .swiper-pagination-bullet {
//         display: inline-block;
//         transition: 200ms transform, 200ms top;
//       }
//     }
//   }
// }
.swiper-container-horizontal, .swiper-controls {
  > .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 8px;
    }
    &.swiper-pagination-bullets-dynamic {
      // left: 50%;
      // transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }
  &.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: 200ms transform, 200ms right;
  }
}
.swiper-controls {
  .swiper-pagination{
    margin-top: 0;
  }
}
/* Progress */
// .swiper-pagination-progressbar {
//   background: rgba(0,0,0,0.25);
//   position: absolute;
//   .swiper-pagination-progressbar-fill {
//     background: var(--swiper-pagination-color, var(--swiper-theme-color));
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     transform: scale(0);
//     transform-origin: left top;
//   }
//   .swiper-container-rtl & .swiper-pagination-progressbar-fill {
//     transform-origin: right top;
//   }
//   .swiper-container-horizontal > &,
//   .swiper-container-vertical > &.swiper-pagination-progressbar-opposite {
//     width: 100%;
//     height: 4px;
//     left: 0;
//     top: 0;
//   }
//   .swiper-container-vertical > &,
//   .swiper-container-horizontal > &.swiper-pagination-progressbar-opposite {
//     width: 4px;
//     height: 100%;
//     left: 0;
//     top: 0;
//   }
// }
// @each $paginationColorName, $paginationColorValue in $colors {
//   .swiper-pagination-#{$paginationColorName} {
//     --swiper-pagination-color: #{$paginationColorValue};
//   }
// }
.swiper-pagination-lock {
  display: none;
}

.swiper-slide{
  background: url(#{$dir-images + 'loader.gif'}) center center no-repeat;
}

.swiper-container{
  padding-bottom: 64px;
  position: relative;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
  

  /* Scrollbar */
.swiper-scrollbar {
    // border-radius: 10px;
    position: relative;
    background: $c-light;
    
    .swiper-container-horizontal > & {
      position: absolute;
      left: 40px;
      bottom: 0;
      z-index: $z-low;
      height: 2px;
      width: calc(100% - 40px);
    }
    // .swiper-container-vertical > & {
    //   position: absolute;
    //   right: 3px;
    //   top: 1%;
    //   z-index: 50;
    //   width: 5px;
    //   height: 98%;
    // }
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: $c-dark;
  // border-radius: 10px;
  left: 0;
  top: 0;
  cursor: pointer;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}

// Navigation
// .swiper-nav{
//   display: flex;
//   justify-content: center;
//   margin: 20px 0;
// }
.swiper-button {
  &-prev,
  &-next {
    position: absolute;
    bottom: 20px;
    z-index: $z-lower;
    outline: none;
    @extend .svg;
    cursor: pointer;
  }
  &-prev {
    left: calc(50% - 54px);
    @extend .svg-prev;
  }
  &-next {
    right: calc(50% - 54px);
    @extend .svg-next;
  }

  &-disabled {
    // opacity: .5;
  }
}

// Zoom
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: zoom-in;

  > img, > svg, > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.swiper-slide-zoomed .swiper-zoom-container{
  cursor: move;
}

// THEME
// .swiper-theme-light {
//   .swiper-pagination-bullet {
//     &::before{
//       background: $c-white;
//     }
//   }
//   .swiper-button{
//     &-prev,
//     &-next {
//       // @extend .svg-swiper-next;
//     }
//   }
// }

// Single Item
.swiper-container.item-1{
  .swiper-pagination, .swiper-button-prev, .swiper-button-next{
    display: none !important;
  }
}