.container{
    padding: 0 $container-padding-x-desktop;
}

// Accordion
.acc{
    &-header{
        margin: 0 $container-padding-x-desktop;
        > button{
            @include font-set(body-xxl);
            @include transitions($func: 'eio');
            &:hover{
                background-color: $c-white;
                color: $c-text-dark;
                padding-left: 35px;
            }
        }
    }
}

.accordion{
    &::after{
        margin: 0 $container-padding-x-desktop;
    }
    .active{
        .acc{
            &-header{
                > button{
                    &:hover{
                        background-color: transparent;
                        color: $c-text-light;
                    }
                }
            }
        }
    }
}