#site-header{
    margin: 100px $container-padding-x-desktop 70px;
    padding-right: $logo-width;
}

.logo{
    position: fixed;
    right: 100px;
    top: 100px;
    z-index: $z-high;
}

.heading{
    @include font-set(body-xxl);
    h1{
        @include font-set(body-xxxl); 
    }
}

.menu{
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($c-dark, .6);
    &-close{
        display: none;
    }
    &-links{
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        a{
            display: inline-block;
            padding-bottom: 3px;
            position: relative;
            @include font-set(body-md);
            &::after{
                content: "";
                position: absolute;
                bottom: -15px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: $c-white;
                opacity: 0;
                @include transitions($dur: fastest, $func: eut);
            }
            &:hover{
                &::after{
                    bottom: 0;
                    opacity: 1;
                }
            }
            &.active{
                &::after{
                    bottom: 0;
                    opacity: 1;
                }
            }
        }
    }
    &-cta{
        display: none;
    }
}