// What We Do
.wwd{
    &-services{
        margin-bottom: 30px;
        @include font-set(body-md);
    }
    &-partners{
        @include font-set(body-md);
    }
}

// Featured Projects
.fpl{
    margin-top: 50px;
    &-header{
        margin-bottom: 20px;
    }
}

// Proje Item
.prj{
    &-header{
        @include font-set(body-lg);
        margin: 0 $container-padding-x-mobile;
    }
    &-body{
        margin: 0 $container-padding-x-mobile;
        // padding: 0 35px;
    }
    &-block1{
        margin-top: 20px;
    }
    &-medias{
        margin-top: 30px;
        .swiper-slide{
            width: 90vw;
        }
    }
}

// 404
.page-404{
    .logo{
        margin-bottom: 0;
    }
}

.pnf{
    height: calc(100vh - 352px);
    &-title{
        @include font-set(body-lg);
    }
    &-desc{
        @include font-set(body-md);
    }
}

// lab
.lab{
    &-list{
        margin-inline: $container-padding-x-mobile;
    }
}

.lit{
    &-header{
        @include font-set(body-lg);
    }
    &-block1{
        margin-top: 20px;
    }
}