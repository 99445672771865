.svg {
	background: url(#{$dir-images + 'sprite-bg.svg'}) no-repeat;
	width: 24px;
	height: 24px;
}

.svg-alien {
	background-position: 0 0;
}

.svg-email {
	background-position: 0 8.333333333333334%;
}

.svg-gem {
	background-position: 0 16.666666666666668%;
}

.svg-hamburger {
	background-position: 0 25%;
}

.svg-link {
	background-position: 0 33.333333333333336%;
}

.svg-magic {
	background-position: 0 41.666666666666664%;
}

.svg-minus {
	background-position: 0 47.36842105263158%;
}

.svg-money {
	background-position: 0 52.77777777777778%;
}

.svg-next {
	background-position: 0 57.89473684210526%;
}

.svg-palette {
	background-position: 0 63.888888888888886%;
}

.svg-phone {
	background-position: 0 72.22222222222223%;
}

.svg-post {
	background-position: 0 80.55555555555556%;
}

.svg-prev {
	background-position: 0 84.21052631578948%;
}

.svg-rocket {
	background-position: 0 91.66666666666667%;
}

.svg-spider {
	background-position: 0 100%;
}

