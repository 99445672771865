// APP SHELL FIX
*{
    box-sizing: border-box;
}

body{
    overflow: auto;
    font-family: $font1;
    @include font-set(body);
    color: $c-white;
    background-color: $c-dark;
}
// #ash-plc{
//     display: none !important;
// }

::selection {
  background: $c-primary;
  color: $c-text-dark;
  text-shadow:none;
}

html {
    scroll-behavior: smooth;
    background-color: $c-dark;
    color: $c-white;
}

img {
    max-width: 100%;
}

picture{
    text-align: center;
    display: inline-block;
    width: 100%;

    img{
        width: auto;
        max-width: 100%;
    }
}

a {
    cursor: pointer;
    outline: none;
    text-decoration: none;
    color: inherit;
}

p{
    a{
        text-decoration: underline;
    }
}

input{
    outline: none;

    &[type=number]{
        -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }
}

textarea{
    resize: none;
}

.clearfix{
    &::after {
        display: block;
        clear: both;
        content: "";
    }
}

figure{
    margin:0;
}

button{
    display: inline-flex;
    padding: 0;
    margin: 0;
    outline: none;
    appearance: none;
    user-select: none;
    cursor: pointer;
    background: none;
    color: inherit;
    text-align: left;
    border: none;
    font-family: $font1;
}

h1, h2, h3, h4, h5, h6{
    font-weight: 300;
}

.ul-clear{
    padding: 0;
    margin: 0;
    list-style: none;
}

.lazy{
    opacity: 0;
    @include transitions($prop: opacity);

    &.loaded, &.error, &.bg-load{
        opacity: 1;
    }
}

.responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &-item, img, video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}
