// Fonts
$font1: 'sanfranciscotext', $font0;

// Font Weights
$fw-thin: 100;
$fw-xlight: 200;
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-sbold: 600;
$fw-bold: 700;
$fw-xbold: 800;
$fw-black: 900;
$fw-xblack: 950;

// Font Sizes
$font-sets: (
    body: (
        font-size: 18,
        line-height: 28
    ),
    body-md: (
        font-size: 20,
        line-height: 26
    ),
    body-lg: (
        font-size: 28,
        line-height: 34
    ),
    body-xl: (
        font-size: 30,
        line-height: 36
    ),
    body-xxl: (
        font-size: 40,
        line-height: 46
    ),
    body-xxxl: (
        font-size: 50,
        line-height: 46
    )
);

// Line Height Base
$lh-base: map-get(map-get($font-sets, body), line-height)/map-get(map-get($font-sets, body), font-size);

// Button
$btn-h-default: 44px;
$btn-h-small: 35px;
$btn-br-factor: .5;

// Colours
$c-brand: #ffffff;
$c-primary: #ffffff;
$c-secondary: #000000;
$c-light: #ffffff;
$c-dark: #000000;
$c-black: #000000;
$c-white: #ffffff;

$c-text-dark: #000000;
$c-text-light: #ffffff;

$emojis: ('spider', 'palette', 'money', 'hamburger', 'gem', 'rocket', 'phone', 'email', 'post', 'link', 'magic');

$container-padding-x-desktop: 100px;
$container-padding-x-mobile: 30px;

$logo-width: 200px;