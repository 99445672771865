// Galaxy
#outerspace{
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-lower;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

#wrapper{
    position: relative;
    z-index: $z-low;
}

// Emoji Holder
#emoji{
    opacity: 0;
    position: fixed;
    z-index: $z-normal;
    pointer-events: none;
    font-size: 0;
    background: #fff;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transitions();
    &::before{
        content: "";
        width: 72px;
        height: 72px;
        @extend .svg;
        transform: scale(1.3888);
    }
    @each $item in $emojis{
        &.#{$item}::before{
            @extend .svg-#{$item};
        }
    }
    &.show{
        animation: show .333s $transition-ease-out forwards;
    }
}

// Emoji Links
.emoji{
    color: $c-white;
    border-bottom: 1px solid $c-white;
    font-style: normal;
}

// What We Do
.wwd{
    &-body{
        max-width: 480px;
    }
}

// Featured Projects
.fpl{
    &-header{
        display: flex;
        align-items: center;
    }
    &-cta{
        margin-left: 50px;
    }
}

// Proje Item
.prj{
    overflow: hidden;
    &-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $c-white;
        padding: 30px;
        width: 180px;
        height: 180px;
        figure{
            width: 100%;
            font-size: 0;
        }
        img{
            width: 100%;
        }
        &.prj-logo-bg{
            background-color: $c-white;
        }
    }
    &-desc, &-services{
        margin-bottom: 20px;
    }
    &-block2{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-medias{
        .swiper-slide{
            @include responsive(1920, 1010);
            max-width: 800px;
            margin-left: 10px;
        }
        img{
            pointer-events: none;
        }
    }
    &-list{
        margin: 100px 0;
        position: relative;
        &::after{
            content: "";
            display: block;
            width: calc(100% - 200px);
            height: 0;
            border-top: 1px solid $c-white;
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
        }
        .prj{
            &-body{
                margin-top: 20px;
                padding-top: 30px;
                padding-left: 0;
            }
        }
    }
    &-header{
        border-top: 1px solid $c-white;
        padding-top: 50px;
    }
    &-launch{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 300px;
        width: 100%;
        &-icon{
            display: inline-block;
            animation: 15s rocket infinite ease;
        }
        .icon{
            font-size: 80px;
            opacity: .85;
            animation: 10s rocket2 infinite $transition-ease-out;
        }
        span{
            display: block;
            margin-top: 20px;
        }
    }
}

// 404
.page-404{
    .footer{
        margin-top: 0;
    }
}

.pnf{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    &-media{
        animation: 15s ufo infinite ease;
        .icon{
            font-size: 100px;
            animation: 8s ufo2 infinite $transition-ease-out;
        }
    }
    &-desc{
        margin-top: 30px;
    }
}

// Lab
.lab{
    &-list{
        margin-block: 100px;
        border-bottom: 1px solid $c-white;
    }
}

.lit{
    padding-block: 50px;
    border-top: 1px solid $c-white;
    &-body{
        margin-top: 50px;
    }
    &-logo{
        width: 180px;
        height: 180px;
        border: 1px solid $c-white;
        padding: 30px;
    }
    &-desc, &-techs, &-status{
        margin-bottom: 20px;
    }
}

// App
.app{
    margin-block: 100px;
    &-frame{
        margin: 0 auto;
        height: 1000px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
}