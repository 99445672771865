#site-header{
    margin: 50px $container-padding-x-mobile 40px;
}

.logo{
    margin-bottom: 50px;
}

.heading{
    @include font-set(body-lg);
    h1{
        @include font-set(body-xxl); 
    }
}

.menu{
    top: 0;
    left: 0;
    width: calc(100% - 68px);
    height: 100%;
    background-color: rgba($c-white, .9);
    padding: 30px;
    color: $c-text-dark;
    transform: translateX(calc(-100% - 68px));
    &-links{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 30px;
        a{
            @include font-set(body-lg);
        }
    }
    &-close{
        position: absolute;
        top: 40px;
        right: 0;
        transform: translateX(100%);
        background-color: rgba($c-white, .9);
        .icon{
            font-size: 48px;
        }
    }
    &-cta{
        position: fixed;
        top: 40px;
        right: 20px;
        background-color: rgba($c-dark, .8);
        .icon{
            font-size: 48px;
        }
    }
    &.opened{
        animation: toRight .222s $transition-ease-out forwards;
    }
}