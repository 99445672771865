svg.icon {
    flex: none !important;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    font-size: 32px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}