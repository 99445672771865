// Mobile Scrollable
%scrollable-h{
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    > * {
        white-space: normal;
    }

    &::-webkit-scrollbar{
        display: none;
    }
}

.container{
    padding: 0 $container-padding-x-mobile;
}

// Accordion
.acc{
    &-header{
        margin: 0 $container-padding-x-mobile;
        > button{
            @include font-set(body-lg);
        }
    }
}

.accordion{
    &::after{
        margin: 0 $container-padding-x-mobile;
    }
}