//font-face
@mixin font-face($family, $file, $fontWeight: normal, $fontStyle: normal) {
    @font-face {
        font-family: $family;
        src:    url(#{$dir-fonts + $file + '.woff2'}) format('woff2'),
                url(#{$dir-fonts + $file + '.woff'}) format('woff');
                // url(#{$dir-fonts + $file + '.ttf'}) format('truetype');
        font-weight: $fontWeight;
        font-style: $fontStyle;
        font-display: swap;
    }
}

// Transition
@mixin transitions($prop: all, $dur: "fastest", $func: "lnr", $delay: 0s){
    $durations: ("now": .111s, "fastest": .222s, "faster": .333s, "normal": .666s, "slow": .999s, "slower": 1.5s);
    $timing-functions: ("lnr": linear, "ein": $transition-ease-in, "eut": $transition-ease-out, "eio": $transition-ease-in-out);

    transition: $prop map-get($durations, $dur) map-get($timing-functions, $func) $delay;
}

// Responsive
@mixin responsive($w: 0, $h: 0) {
    .responsive {
        @content;

        @if($w != 0 and $h != 0){
            &::before {
                padding-top: percentage($h / $w);
            }
        }
    }
}

// Font Sets
@mixin font-set($set, $update: false){
    $fs: null;
    $set: map-get($font-sets, $set);

    @if($update){
        font-size: map-get($set, font-size)/$px-to-rem#{rem};
        line-height: map-get($set, line-height)/map-get($set, font-size);
    }@else{
        @each $k, $v in $set{
            @if($k == font-size){
                #{$k}: $v/$px-to-rem#{rem};
                $fs: $v;
            }@else if($k == line-height){
                #{$k}: $v/$fs;
            }@else{
                #{$k}: $v;
            }
        }
    }
}

// Container Aligner with Padding
@mixin cont-aligner($max-width){
    padding-left: calc((100% - #{$max-width}) / 2);
}

// Overflow hidden by line count
@mixin line-clamp($lines, $fs: '', $overflow: ellipsis){
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    text-overflow: $overflow;

    @if($fs != ''){
        height: #{map-get(map-get($font-sets, $fs), line-height) * $lines}px;
    }
}

// Background Icon
@mixin svg-icon($icon){
    @extend .svg;
    @extend .svg-#{$icon};
}