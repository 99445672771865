// Accordion
.acc{
    &-header{
        border-top: 1px solid $c-white;
        > button{
            padding: 30px 0;
            width: 100%;
            position: relative;
        }
    }
    &-body{
        display: none;
    }
}

.accordion{
    &::after{
        content: "";
        display: block;
        height: 0;
        border-top: 1px solid $c-white;
    }
    // States
    .active{
        > .acc{
            &-header{
                > button{
                    padding-left: 35px;
                    &::before{
                        content: "";
                        @include svg-icon('minus');
                        position: absolute;
                        left: 0;
                        top: 40px;
                    }
                }
            }
            
            &-body{
                display: block;
            }
        }
    }
}

// Preloader
.preloader{
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-ultra;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(#{$dir-images + 'loader.gif'}) center center no-repeat;
    background-color: rgba($c-white, .2);
}

// Page Load
.loader{
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-master;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #000;
    text-align: center;
    pointer-events: none;
    opacity: 0;
    &::before{
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
    &.loading-page{
      animation: loaderShow 1s $transition-ease-out forwards;
      pointer-events: all;
    }
    &.waiting{
      animation: show .666s $transition-ease-out forwards;
      pointer-events: all;
    }
}

.wall{
    background: url(#{$dir-images + 'page_load.jpg'});
    background-size: cover;
  }
  
  .scene{
    display: inline-block;
    vertical-align: middle;
    perspective: 5px;
    perspective-origin: 50% 50%;
    position: relative;
  }
  
  .wrap{
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: -500px;
    top: -500px;
    transform-style: preserve-3d;
    animation: loaderMove 12s infinite linear;
    animation-fill-mode: forwards;
    
  }
  
  .wrap:nth-child(2){
    animation: loaderMove 12s infinite linear;
    animation-delay: 6s;
  }
  
  .wall {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    animation: loaderFade 12s infinite linear;
    animation-delay: 0;
  }
  
  .wrap:nth-child(2) .wall {
    animation-delay: 6s;
  }
  
  .wall-right {
    transform: rotateY(90deg) translateZ(500px);
  }
  
  .wall-left {
    transform: rotateY(-90deg) translateZ(500px);
  }
  
  .wall-top {
    transform: rotateX(90deg) translateZ(500px);
  }
  
  .wall-bottom {
    transform: rotateX(-90deg) translateZ(500px);
  }
  
  .wall-back {
    transform: rotateX(180deg) translateZ(500px);
  }

  // Custom Scroll Bar
%scrollbar{
  &::-webkit-scrollbar {
      width: 4px;
  }
  // &::-webkit-scrollbar-button {
  //     background: $c-bg-grey;
  // }
  &::-webkit-scrollbar-thumb {
      background: $c-white;
      border-radius: 30px;
  }
  // &::-webkit-scrollbar-thumb:hover {
  //     background: $c-grey-dark;
  // }
  &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
  }
  // &::-webkit-scrollbar-track-piece {
  //     background: $c-bg-grey;
  // }
  // &::-webkit-scrollbar-corner {
  //     background: $c-bg-grey;
  // }
  // &::-webkit-resizer {
  //     background: #111;
  // }
  @supports(scrollbar-color: transparent $c-white){
      * {
          scrollbar-color: transparent $c-white;
          scrollbar-width: thin;
      }
  }
}