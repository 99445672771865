//RESET
@import 'vendors/reset';

//ABSTRACTS
@import 'abstracts/variables';
@import 'abstracts/brand-settings';
@import 'abstracts/mixins';
@import 'abstracts/app-shell-source';

//VENDORS
@import 'vendors/swiper-custom';

//BASE
@import 'base/typography';
@import 'base/icons';
@import 'base/icons-bg';
@import 'base/animations';
@import 'base/global';

//BLOCKS
@import 'layouts/elements';
@import 'blocks/header';
@import 'blocks/footer';

//MODULES


//LAYOUT
@import 'layouts/pages';

// PAGE LOAD INTERACTIONS
// @import 'layouts/pageload-interactions';


// MOBILE (<= 1024px)
@media (max-width: $breakpoint-mobile-2) {
  
  //BLOCKS
  @import 'layouts/elements-mobile';
  @import 'blocks/header-mobile';
  @import 'blocks/footer-mobile';

  //MODULES


  //LAYOUT
  @import 'layouts/pages-mobile';

  // PAGE LOAD INTERACTIONS
  // @import 'layouts/pageload-interactions-mobile';

  // TABLET (> 480px)
  @media (min-width: $breakpoint-mobile-1) {
    @import 'layouts/tablets-only';
  }
}

// DESKTOP (> 1024px)
@media (min-width: $breakpoint-desktop-1) {
  // BLOCKS
  @import 'layouts/elements-desktop';
  @import 'blocks/header-desktop';
  @import 'blocks/footer-desktop';

  // MODULES


  // LAYOUT
  @import 'layouts/pages-desktop';

  // PAGE LOAD INTERACTIONS
  // @import 'layouts/pageload-interactions-desktop';
  
  // MID-SIZE DEVICES FIX (> 1024px && <= 1300px)
  @media (max-width: $breakpoint-desktop-2) {
    @import 'layouts/midsize-devices-only';
  }
}