// Directories
$dir-images: '../images/';
$dir-fonts: 'fonts/';

// Fonts
$font0: 'arial', 'sans-serif';

// 1rem default 16px = xx/16
$px-to-rem: 16;

// z-index
$z-zero:0;
$z-lower:1;
$z-low:2;
$z-normal:3;
$z-supnormal:4;
$z-high:5;
$z-higher:6;
$z-highest:7;
$z-super:8;
$z-ultra:9;
$z-master:10;

// Breakpoints
$breakpoint-mobile-1: 480px;
$breakpoint-mobile-2: 1024px;
$breakpoint-desktop-1: 1025px;
$breakpoint-desktop-2: 1300px;


// Transitions
$transition-ease-in: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$transition-ease-out: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$transition-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Containers
$containers: ();
$container-padding-x-mobile: 20px;
$container-padding-x-desktop: 20px;

// Misc in Site
$scrollbar-width: 16px;
$header-height-mobile: 51px;
$header-height-desktop: 61px;